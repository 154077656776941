export const isBrowser = typeof window !== "undefined"

export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const getLocalStorage = key => {
  const value = isBrowser && JSON.parse(window.localStorage.getItem(key))
  return value
}

export const removeFromLocalStorage = key => {
  window.localStorage.removeItem(key)
}

export const clearLocalStorage = () => {
  window.localStorage.clear()
}
