import React from "react"

import { GlobalContextProvider } from "../context/context"

const RootLayout = ({ children }) => (
  <>
    <GlobalContextProvider>{children}</GlobalContextProvider>
  </>
)

export default RootLayout
