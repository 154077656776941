import React, { useContext, useState, useEffect } from "react"
import {
  getLocalStorage,
  isBrowser,
  setLocalStorage,
} from "../localStorageService/localStorageService"

const initialLinksOrder = [
  {
    id: 1,
    path: "/use-cases/creative-agencies/",
    text: "Creative Agencies",
  },
  {
    id: 2,
    path: "/use-cases/marketing-agencies/",
    text: "Marketing Agencies",
  },
  {
    id: 3,
    path: "/use-cases/dev-teams/",
    text: "Dev Teams",
  },
  {
    id: 4,
    path: "/use-cases/in-house-creative-teams/",
    text: "In-House Creative Teams",
  },
  {
    id: 5,
    path: "/use-cases/customer-support-teams/",
    text: "Customer Support Teams",
  },
]

const initialState = {
  modifiedLinksOrder: initialLinksOrder,
}

const Context = React.createContext(initialState)

const GlobalContextProvider = ({ children }) => {
  // Subnav logic.
  const navLinksOrder = getLocalStorage("nav-links-order")
    ? getLocalStorage("nav-links-order")
    : initialLinksOrder

  const [dynamicWindowSize, setDynamicWindowSize] = useState(
    isBrowser.innerWidth
  )
  const [modifiedLinksOrder, setModifiedLinksOrder] = useState(navLinksOrder)

  const checkSize = () => {
    setDynamicWindowSize(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", checkSize)
    return () => {
      window.removeEventListener("resize", checkSize)
    }
  })

  const handleCurrentLocation = path => {
    const currentLink = initialLinksOrder.find(item => item.path === path)
    const newLinksOrder = initialLinksOrder.filter(
      item => item.path !== currentLink
    )
    newLinksOrder.unshift(currentLink)
    setLocalStorage("nav-links-order", newLinksOrder)

    setModifiedLinksOrder(prev => {
      return Array.from(new Set(newLinksOrder))
    })
  }

  // User Info
  const localStorageUserInfo = getLocalStorage("sideline-client-info")

  const [userInfo, setUserInfo] = useState(
    localStorageUserInfo
      ? localStorageUserInfo
      : {
          name: "",
          email: "",
          company_name: "",
          domain: "",
          features_package: "",
          newsletter: false,
        }
  )

  // Stripe
  const [packageError, setPackageError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [subscriptionType, setSubscriptionType] = useState("monthly")

  return (
    <Context.Provider
      value={{
        modifiedLinksOrder,
        initialLinksOrder,
        dynamicWindowSize,
        handleCurrentLocation,
        userInfo,
        setUserInfo,
        packageError,
        setPackageError,
        subscriptionType,
        setSubscriptionType,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </Context.Provider>
  )
}

const useGlobalContext = () => {
  return useContext(Context)
}

export { GlobalContextProvider, useGlobalContext }
