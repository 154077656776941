/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"

import RootLayout from "./src/rootLayout/RootLayout"
import "./src/styles/base/basic.scss"
import "./src/styles/utilities/layout.scss"
import "./src/styles/utilities/text.scss"
import "./src/styles/base/fonts.scss"
import "./src/styles/regions/header.scss"
import "./src/styles/regions/main.scss"
import "./src/styles/regions/footer.scss"
import "./src/styles/components/global/block.scss"
import "./src/styles/components/global/block--menu-trigger.scss"
import "./src/styles/components/global/block--page-top-content.scss"
import "./src/styles/components/global/block--disclaimer.scss"
import "./src/styles/components/global/block--social-networks.scss"
import "./src/styles/components/global/menu--main.scss"
import "./src/styles/components/global/menu--footer.scss"
import "./src/styles/components/global/menu--static.scss"
import "./src/styles/components/global/button.scss"
import "./src/styles/components/global/link.scss"
import "./src/styles/components/global/icon.scss"
import "./src/styles/components/global/form.scss"

export const wrapRootElement = ({ element }) => (
  <RootLayout>{element}</RootLayout>
)
